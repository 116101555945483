<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
     <div class="head">
        <div class="left">
          <b class="left_tit">标签列表</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <!-- 筛选部分 -->
      <!-- 表格头部 -->
      <div class="table_head">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
        <div class="table_head_right">
         <ul>          
            <li>
              <el-button size="mini" @click="show = !show">
                添加
              </el-button>
            </li>
          </ul>
          <transition name="el-fade-in">
            <div v-show="show" class="transition-box">
              <el-form
                ref="form"
                :model="sizeForm"
                label-width="80px"
                size="mini"
              >
                <div class="act_tit">添加会员等级</div>
				
				
			<div class="listcont">
							<div class="shuai" v-if="admin_type==1">
								<!-- <div class="selname"></div> -->
								<el-form-item label="选择商户：" label-width="120px" style="margin-top: 5px;">
								</el-form-item>
								<el-select                       v-model="pstoreid"
									                      placeholder="请选择商户"                       
									                    >
									                      <el-option
										                        v-for="item in selects"
										                        :key="item.storeid"
										                        :label="item.s_name"
										                        :value="item.storeid"
										@click.native="shopType(item.storeid)"                       >
										                      </el-option>
									                    </el-select>
								<!-- <Select  placeholder="请选择" class="sel" v-model="selects.storeid">
									
			<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)"></Option>
			 </Select> -->
							</div>
						<!-- 	<div class="shuai">
								<div class="selname" ></div>
								<el-form-item label="选择门店：" label-width="120px" style="margin-top: 5px;">
								</el-form-item>
								<el-select                       v-model="storeid"
									                      placeholder="请选择门店"                     >
									                      <el-option
										                        v-for="item in selectp"
										                        :key="item.storeid"
										                        :label="item.s_name"
										                        :value="item.storeid"
										@click.native="storeidType(item.storeid)"                       >
										                      </el-option>
									                    </el-select>
							</div> -->
						</div>

                <el-form-item label="会员等级名称：" label-width="120px">
                  <el-input v-model="g_name"></el-input>
                </el-form-item>


                <el-form-item label="成长值满足点：" label-width="120px">

                 <el-input v-model="g_val"></el-input>
                </el-form-item>
				<el-form-item label="是否默认等级：" label-width="120px">
				 <template slot-scope="scope">
				           
				           <el-switch
				             @change="ischeck(is_checkde)"
				             v-model="is_checkde"
				             active-color="#13ce66"
				             inactive-color="#ff4949"
				             :active-value="2"
				             :inactive-value="1"
				           >
				           </el-switch>
				           
				         </template>
				</el-form-item>
                <el-form-item label="免运费标准：" label-width="120px">
                  <el-input v-model="freight"></el-input>
                </el-form-item>
				<el-form-item label="奖励评价：" label-width="120px">
				  <el-input v-model="comment"></el-input>
				</el-form-item>

           
                <el-form-item size="large">
                  <el-button type="primary" @click="onSubmit"
                    >提交</el-button
                  >
                  <el-button @click="show = false">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
          </transition>
       
        </div>
      </div>
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
        <el-table
          :data="labelList"
          border
          style="width: 85%; margin-left: 200px; text-align: center"
        >
		<!-- <el-table-column prop="ml_id" label="ID" align="center">
		</el-table-column> -->
          <el-table-column prop="g_name" label="等级名称" align="center">
          </el-table-column>
          <el-table-column prop="is_checkde" label="默认会员等级" align="center">
			     <template slot-scope="scope">
			              <!-- <el-tooltip :content="'Switch value: ' + scope.row.is_online" placement="top"> -->
			              <el-switch
			                @change="switchChange(scope.row.mg_id)"
			                v-model="scope.row.is_checkde"
			                active-color="#13ce66"
			                inactive-color="#ff4949"
			                :active-value="2"
			                :inactive-value="1"
			              >
			              </el-switch>
			              <!-- </el-tooltip> -->
			            </template>
          </el-table-column>

      
          <el-table-column prop="g_val" label="成长值满足点" align="center">
          </el-table-column>
          <el-table-column prop="freight" label="免运费标准" align="center">
			  <template slot-scope="scope">
			                <span>{{
			                 scope.row.freight
			                }}</span>元/每笔

			              </template>
          </el-table-column>
         <el-table-column prop="comment" label="评价奖励" align="center">
			 <template slot-scope="scope">
			               +<span>{{
			                scope.row.comment
			               }}</span>成长值/条
			 
			             </template>
         </el-table-column>
          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <span
                style="margin-left: 10px"
                @click="
                  edit(
                    
                    scope.row.g_name,
                    scope.row.g_val,
                    scope.row.freight,
					scope.row.comment,
					scope.row.storeid,
					scope.row.mg_id,
					scope.row.is_checkde
                  )
                "
              >
                编辑
              </span>
              <span
                style="margin-left: 10px"
                @click="delActive(scope.row.mg_id,)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="titlePage"
        >
        </el-pagination>
        <div></div>
      </div>

      <!-- <el-popover placement="end"  width="500" height="500px" trigger="click">
        <el-row :gutter="12" style="border:1px solid red">
          <el-col :span="8">
            <el-card shadow="always"> 总是显示 </el-card>
          </el-col>
        </el-row>

        <el-button slot="reference">click 激活</el-button>
      </el-popover> -->
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "6-4",
      top2: ["6"],
      flag: 1,
      admin_type: "",
	  xstoreid:'',
      labelList:[],
      selects:'',
	  selectp:'',
      activeTitle: "",
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
      business_id: '', */
      businessList: [{}],
      business_id: "",
      titlePage:10,
      storeList: [{}],
      storeid: "",
	  pstoreid:'',
	  freight:'',
	  is_checkde:'',
	  comment:'',
	  g_name:"",
	  g_val:"",
	  mg_id:'',
      //添加活动
      sizeForm: {
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
 
    //修改上下架
  
    //刷新页面按钮
    refresh() {
      location.reload();
    },
 
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.memberGradeList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
	  

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.memberGradeList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
	  
     
    },
	switchChange(val)
	{
		let token = this.$storage.getLocal('token');
		let login_type=this.$storage.getLocal('type')
		this.$request.memberGradeCheckdeUp({token,mg_id:val}).then(r=>{
			if(r.code == 0){ 
		    this.$request.memberGradeList({token,storeid:'',login_type:login_type,page:'1',limit:'10'}).then(r=>{
		    	// console.log(123)
		    	if(r.code == 0){ 
		    		console.log(r)
		    		this.labelList=r.data.data
		    	}
		    })
			}
		})
	},
    delActive(val){
		console.log(val)
		let token = this.$storage.getLocal('token');
		this.$request.memberGradeDel({token,mg_id:val}).then(r=>{
			if(r.code == 0){ 
				location.reload();
			}
		})
	},
    onSubmit() {
    let login_type=this.$storage.getLocal('type')
    let token = this.$storage.getLocal('token');
	if(this.mg_id=='')
	{
		this.$request.memberGradeAdd({
			token,
			storeid:this.storeid,
			login_type:login_type,
			g_name:this.g_name,
			g_val:this.g_val,
			is_checkde:this.is_checkde,
			freight:this.freight,
			comment:this.comment
			}).then(r=>{
			// console.log(123)
			if(r.code == 0){ 
				this.show=false
				location.reload();
			}
		})
	}else
	{
		this.$request.memberGradeUp({
			token,
			storeid:this.storeid,
			login_type:login_type,
			mg_id:this.mg_id,
			g_name:this.g_name,
			g_val:this.g_val,
			is_checkde:this.is_checkde,
			freight:this.freight,
			comment:this.comment
			}).then(r=>{
			// console.log(123)
			if(r.code == 0){ 
				this.show=false
				location.reload();
			}
		})
	}

    },
 
  shopType(value){
	  this.storeid=value
  	// let token = this.$storage.getLocal('token');
  	// this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
  	// 	console.log(123)
  	// 	if(r.code == 0){ 
  	// 		this.selectp = r.data;
  	// 		console.log(r)
  	// 	}
  	// })
  },
  storeidType(value)
  {
  	this.storeid=value
  },
    //编辑活动的提交按钮
    onChange() {
      if (
        this.sizeForm2.title == "" 
      ) 
	  {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
 
      }
    },
    edit(g_name,g_val,freight,comment,storeid,mg_id,is_checkde)
	{
		// console.log(val)
		this.show=true
		this.g_name=g_name
		this.g_val=g_val
		this.freight=freight
		this.comment=comment
		this.mg_id=mg_id
		this.storeid=storeid
		this.is_checkde=is_checkde
		let token = this.$storage.getLocal('token');
		this.$request.getUpStoreData({
			token,
			storeid: storeid,
		}).then(r => {
			if (r.code == 0) {
				console.log(r)
				this.pstoreid = r.data.pstoreid
				this.$request.getStoreDataList({
					token,
					pstoreid: r.data.pstoreid,
					login_type: this.$storage.getLocal('type')
				}).then(r => {
					console.log(123)
					if (r.code == 0) {
						this.selectp = r.data;
						console.log(r)
					}
				})
				
			}
		})
	}
 
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
   let login_type=this.$storage.getLocal('type')
   this.admin_type=login_type
   let token = this.$storage.getLocal('token');
   this.$request.memberGradeList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize}).then(r=>{
   	// console.log(123)
   	if(r.code == 0){ 
   		console.log(r)
		this.titlePage=r.data.count
		this.labelList=r.data.data
   	}
   })

   if(login_type=='1')
   {
   	this.$request.getMerchantData({token}).then(r=>{
   		if(r.code == 0){ 
   			this.selects = r.data;
   			console.log(r)
   	
   		}
   	})
   }else if(login_type=='2')
   {
   	this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
   		if(r.code == 0){ 
   			this.selectp = r.data;
   			console.log(r)
   		}
   	})
   }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 710px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 200px;
		border: 1px solid black;
	
	}
	.sel
	{
		/* margin-left: 10px; */
		margin-top: 5px;
	}
</style>